import Vue from "vue";
import App from "./App.vue";
import "./main.css";
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faPlus, faSortAmountUpAlt} from "@fortawesome/free-solid-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faSortAmountDownAlt} from "@fortawesome/free-solid-svg-icons/faSortAmountDownAlt";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {faPrint} from "@fortawesome/free-solid-svg-icons/faPrint";

library.add(faPlus, faTimes, faSortAmountDownAlt, faSortAmountUpAlt, faArrowLeft, faArrowRight, faPrint);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
    render: h => h(App)
}).$mount("#app");
