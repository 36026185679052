<template hidden>
    <main class="h-full bg-gray-100">
        <Navigation/>
        <div class="container mx-auto px-3">
            <Calculator></Calculator>
        </div>
        <footer class="py-6 mt-10">
            <div class="container mx-auto px-3">
                <section class="text-gray-600 mb-10">
                    <h2 class="font-bold text-2xl mb-3">About this Calculator</h2>
                    <p class="mb-3">I had been looking for financial calculators online that could help me with my goals of paying off debts and saving money. I couldn't find any that would let me calculate paying off a credit card, saving a certain amount, and <em>then</em> paying off other debts. None of the ones I found allowed this kind of planning and a lot of them were trying to sell me on getting a Credit Card! I just felt like this kind of planning shouldn't be this hard.
                    </p>
                    <p class="mb-5">Being a software developer, I decided to scratch my itch and build something that fit my needs. So I sat down a couple of weekends and wrote this tool I'm calling Goals. Hopefully, it's as helpful to you in helping you plan for your goals as it was for me. If you have any problems or positive feedback, please view my <a class="text-blue-600"
                                                               href="https://gitlab.com/mrcleanandfresh/goals/-/issues/new">Issue
                            Tracker</a>.</p>
                    <p class="text-gray-500 text-sm">This tool was built using <a class="text-blue-600"
                                                                          href="https://vuejs.org/" title="Vue">Vue</a>
                        and <a class="text-blue-600" href="https://tailwindcss.com/" title="TailwindCSS">TailwindCSS</a>.
                        All the calculations are done in the browser.</p>
                </section>
                <p class="text-center text-gray-900">&copy; {{copyright}} <a
                        class="text-blue-600"
                        href="https://kevindench.design/?utm_source=goals&utm_medium=footer&utm_campaign=referral"
                        target="_blank">Kevin Dench Design, LLC.</a>, All Rights Reserved.</p>
            </div>
        </footer>
    </main>
</template>

<script>
    import Navigation from "./components/Navigation";
    import Calculator from "./components/Calculator";

    export default {
        name: "app",
        components: {
            Navigation,
            Calculator
        },
        computed: {
            copyright: function () {
                const year = new Date();
                if (year.getFullYear() > 2019) {
                    return `2019-${year.getFullYear()}`;
                } else {
                    return `2019`
                }
            }
        }
    };
</script>
