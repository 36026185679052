import moment from "moment";
import {CalculationSubType, CalculationType} from "./../components/Calculation";
import {$ as m$, in$} from 'moneysafe';
import CleaningService from "./CleaningService";
import {Decimal} from 'decimal.js';

export function calculateTotalPrincipal(amortization) {
    if (Array.isArray(amortization)) {
        return amortization.reduce((sum, item) => (in$(m$(sum) + m$(item.principalPayment))), m$(0));
    } else {
        return 0;
    }
}

export function calculateTotalInterest(amortization) {
    if (Array.isArray(amortization)) {
        return amortization.reduce((sum, item) => (in$(m$(sum) + m$(item.interestPayment))), m$(0));
    } else {
        return 0;
    }
}

/**
 * Calculating the Interest payment
 * Because JavaScript uses 64-bit floating point representation, which is the same as Java's `double`, numbers are represented
 * in this format as a whole number times a power of two. Rational numbers such as 0.1 or 1/10 whose denominator is not
 * a power of two cannot be exactly represented.
 * Therefore there are a couple options when using financial calculations:
 * - Scale decimal values by 100, and represent all monetary values in whole cents, such as 2550 cents instead of 25.50 dollars.
 * - We can use a library like money$afe to do that for us, which is what we did.
 * @see https://stackoverflow.com/a/588014/4233452
 * @see https://stackoverflow.com/a/2876619/4233452
 * @param balance - the balance to calculate the interest payment from
 * @param rate - the interest rate
 * @return {number} - the result of the calculated interest.
 */
export function calculateInterestPayment(balance, rate) {
    return in$(m$(balance) * ((rate * 0.01) / 12));
}

/**
 * Calculate the interest payment for a credit card.
 *
 * Credit card payments are calculated in 4 steps:
 * 1. Divide APR by the number of days in a year to determine your daily periodic rate. `((rate * 0.01) / 365)`
 * 2. Determine your average daily balance. Write down each day’s balance, starting with any unpaid balance carried
 * over from the previous month. Once that is done, add it up and then divide by the number of days in the billing cycle.
 * 3. Multiply the average daily balance by the daily periodic rate.
 * 4. Multiply the result by the number of days in your billing cycle (30 days).
 *
 * @param balance - the balance on the card. For Simplicity, it will also represent the average daily balance.
 * @param rate - the standard APR on the credit card.
 * @param daysInBillingPeriod - the billing period's length, normally 30.
 * @return {number} - the interest payment
 */
export function calculateInterestPaymentForCreditCard(balance, rate, daysInBillingPeriod) {
    const dailyPeriodicRate = new Decimal((rate * 0.01) / 365).toSignificantDigits(2);
    return in$(m$(balance) * dailyPeriodicRate * daysInBillingPeriod);
}

/**
 * Calculate the Simple Interest Payment for an Auto Loan.
 *
 * The Simple Interest payment formula is `S = P(r)(t)` where S is Simple Interest, P is Principal, r is rate, and t is time.
 * Since we're calculating one payment, we're not concerned about time.
 *
 * @param balance
 * @param rate
 */
export function calculateInterestPaymentForAuto(balance, rate) {
    rate = new Decimal(rate / 12);
    return in$(new Decimal(balance).mul(rate));
}

/**
 * Calculate the Principal Payment
 * Because JavaScript uses 64-bit floating point representation, which is the same as Java's `double`, numbers are represented
 * in this format as a whole number times a power of two. Rational numbers such as 0.1 or 1/10 whose denominator is not
 * a power of two cannot be exactly represented.
 * Therefore there are a couple options when using financial calculations:
 * - Scale decimal values by 100, and represent all monetary values in whole cents, such as 2550 cents instead of 25.50 dollars.
 * - We can use a library like money$afe to do that for us, which is what we did.
 * @param payment - the payment that will be made
 * @param interestPayment - the calculated interest payment
 * @return {number} - the result of the payment sans the interest payment.
 */
export function calculatePrincipalPayment(payment, interestPayment) {
    return in$(m$(payment) - m$(interestPayment));
}

/**
 * Calculate the new balance
 * Because JavaScript uses 64-bit floating point representation, which is the same as Java's `double`, numbers are represented
 * in this format as a whole number times a power of two. Rational numbers such as 0.1 or 1/10 whose denominator is not
 * a power of two cannot be exactly represented.
 * Therefore there are a couple options when using financial calculations:
 * - Scale decimal values by 100, and represent all monetary values in whole cents, such as 2550 cents instead of 25.50 dollars.
 * - We can use a library like money$afe to do that for us, which is what we did.
 * @param balance - the current balance of the loan or savings account
 * @param principalPayment - the principle payment which is the payment minus the interest.
 */
export function calculateNewBalance(balance, principalPayment) {
    return in$(m$(balance) - m$(principalPayment));
}

/**
 * Calculation Object
 * @param type {CalculationType} - The type of calculation.
 * @param subType {CalculationSubType} - The sub-type of the calculation.
 * @param identifier {string} - The identifier, can be anything, as long as it's a string.
 * @param payment {string|number} - The payment that will be used for the calculation.
 * @param interestRate {string|number} - The interest rate that will be used for the calculation.
 * @param balance {string|number} - The current balance of the account.
 * @param goal {string|number} - Only applicable if a {@link CalculationType.SAVINGS} type.
 * @constructor
 */
export function CalculationResult(type, subType, identifier, payment, interestRate, balance, goal) {
    const now = moment();
    this.type = type;
    this.subType = subType;
    this.name = identifier;
    this.payment = CleaningService.cleanIfString(payment);
    this.interestRate = CleaningService.cleanIfString(interestRate);
    this.amortization = [];
    this.payments = [];
    this.months = 0;
    this.balance = CleaningService.cleanIfString(balance);
    this.goal = CleaningService.cleanIfString(goal);
    this.beginMoment = now.add(1, 'month').startOf('month').clone();
    this.endMoment = now.add(1, 'month').startOf('month').clone();
}

/**
 * Map Calculations
 * We want to map the calculations into a standardized object that can be used for all calculations.
 * @param calculations - the raw calculations from the user input.
 * @return {CalculationResult} - return the Calculation object
 */
export function mapCalculations(calculations) {
    return calculations.map((calculation, index) => {
        let identifier;
        switch (calculation.type) {
            case CalculationType.LOAN:
                identifier = `${calculation.subType} ${index + 1}`;
                break;
            case CalculationType.SAVINGS:
                identifier = `Savings ${index + 1}`;
                break;
        }
        return new CalculationResult(
            calculation.type,
            calculation.subType,
            identifier,
            calculation.payment,
            calculation.interestRate,
            calculation.balance,
            calculation.goal
        );
    });
}

/**
 * Complete the given calculation
 * @param mappedCalculations - The calculations that have been mapped
 * @param i - the current index of the calculation
 * @param previousPaymentsTotal - the total of the previous payments
 * @param payment - the current payment
 * @param indexesOfCompleteCalculations - finished calculations
 * @param calcLength - the total length of the calculations
 */
export function completeCalculation(mappedCalculations, i, previousPaymentsTotal, payment, indexesOfCompleteCalculations, calcLength) {
    mappedCalculations[i].payments = [
        ...previousPaymentsTotal,
        {
            name: mappedCalculations[i].name,
            value: payment
        }
    ];
    indexesOfCompleteCalculations.push(i);
    if (indexesOfCompleteCalculations.length > 0) {
        // iterate over the indexes in the mapped calculations, in sequence
        for (let num = 0; num < calcLength; ++num) {
            // if we bump into one that isn't complete
            if (indexesOfCompleteCalculations.indexOf(num) === -1) {
                // add our completed payment to it.
                mappedCalculations[num].payments = [
                    ...previousPaymentsTotal,
                    {
                        name: mappedCalculations[i].name,
                        value: payment
                    }
                ];
                break;
            }
        }
    }
}

/**
 * Calculate the results
 * @param calculations {*} - Raw user input in the form of an array of objects.
 * @return {CalculationResult} - The Calculation object results that have been sorted
 */
export function calculate(calculations) {
    const mappedCalculations = mapCalculations(calculations);
    const calculationSubType = new CalculationSubType();

    let isNotDone = true;
    const calcLength = mappedCalculations.length;
    const indexesOfCompleteCalculations = [];

    // These calculations need to run in parallel, because each month all loans will have to be paid, reducing the balance.
    do {
        for (let i = 0; i < calcLength; ++i) {
            if (indexesOfCompleteCalculations.indexOf(i) !== -1) {
                continue;
            }

            const amortization = mappedCalculations[i].amortization;
            let totalMoment = mappedCalculations[i].endMoment; // Increment the end date
            let previousPaymentsTotal = mappedCalculations[i].payments;
            let originalBalance = mappedCalculations[i].balance; // previously cleaned, when mapped.
            let newBalance = originalBalance;
            if (amortization.length > 0) {
                newBalance = amortization[amortization.length - 1].balance;
            }

            const startMoment = mappedCalculations[i].beginMoment;
            const payment = mappedCalculations[i].payment; // previously cleaned, when mapped.

            switch (mappedCalculations[i].type) {
                case CalculationType.LOAN: {
                    const additionalPrincipalPayment = previousPaymentsTotal.reduce((sum, previousPayment) => sum + previousPayment.value, 0);
                    let interestPayment;
                    switch (mappedCalculations[i].subType) {
                        case calculationSubType.LOAN_CREDIT_CARD :
                            interestPayment = calculateInterestPaymentForAuto(newBalance, mappedCalculations[i].interestRate);
                            break;
                        case calculationSubType.LOAN_AUTO :
                            interestPayment = calculateInterestPaymentForAuto(newBalance, mappedCalculations[i].interestRate);
                            break;
                        case calculationSubType.LOAN_MORTGAGE:
                        default:
                            interestPayment = calculateInterestPayment(newBalance, mappedCalculations[i].interestRate);
                    }
                    let principalPayment = calculatePrincipalPayment(in$(m$(payment) + m$(additionalPrincipalPayment)), interestPayment);

                    if (principalPayment > newBalance) {
                        principalPayment = newBalance;
                    }

                    newBalance = calculateNewBalance(newBalance, principalPayment);

                    let isLastBalanceNotZero = true;
                    if (amortization.length > 0) {
                        isLastBalanceNotZero = amortization[amortization.length - 1].balance !== 0;
                    }

                    if (newBalance >= 0 && isLastBalanceNotZero) {
                        amortization.push({
                            interestPayment,
                            principalPayment,
                            balance: newBalance,
                        });
                        totalMoment.add(1, 'month');
                    } else {
                        completeCalculation(mappedCalculations, i, previousPaymentsTotal, payment, indexesOfCompleteCalculations, calcLength);
                        if (indexesOfCompleteCalculations.length === calcLength) {
                            isNotDone = false;
                        }
                    }

                    break;
                }
                case CalculationType.SAVINGS: {
                    const savingsGoal = mappedCalculations[i].goal; // previously cleaned, when mapped.
                    const additionalSavingsPayment = previousPaymentsTotal.reduce((sum, previousPayment) => sum + previousPayment.value, 0);
                    let paymentToBalance = in$(m$(payment) + m$(additionalSavingsPayment));

                    newBalance = in$(m$(newBalance) + m$(paymentToBalance));

                    amortization.push({
                        interestPayment: null,
                        principalPayment: paymentToBalance,
                        balance: newBalance,
                    });
                    totalMoment.add(1, 'month');

                    if (newBalance > savingsGoal) {
                        completeCalculation(mappedCalculations, i, previousPaymentsTotal, payment, indexesOfCompleteCalculations, calcLength);
                        if (indexesOfCompleteCalculations.length === calcLength) {
                            isNotDone = false;
                        }
                    }
                    break;
                }
            }

            mappedCalculations[i].months = totalMoment.diff(moment(), 'months');
            mappedCalculations[i].balance = originalBalance;
            mappedCalculations[i].beginMoment = startMoment;
            mappedCalculations[i].endMoment = totalMoment;
        }
    } while (isNotDone);

    // sort by finished first.
    mappedCalculations.sort((a, b) => {
        if (a.payments.length > b.payments.length) {
            return 1;
        }
        if (a.payments.length < b.payments.length) {
            return -1;
        }
        return 0;
    });

    return mappedCalculations;
}
