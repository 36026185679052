import CleaningService from "./CleaningService";

export default class ValidationService {
    static isValid(value, field, rules, validation) {
        if (value !== null) {
            value = parseFloat(value);
            if ( rules.min !== undefined && rules.max !== undefined ) {
                if ( value >= parseFloat(rules.min) && value <= parseFloat(rules.max) ) {
                    return true;
                } else {
                    if (value <= parseFloat(rules.min)) {
                        validation.get(field).errors.set('min', `That number is too low.`);
                    } else {
                        validation.get(field).errors.set('max', `That number is too high.`);
                    }
                    return false;
                }
            } else if (rules.min !== undefined) {
                if (value >= parseFloat(rules.min)) {
                    return true;
                } else {
                    validation.get(field).errors.set('min', `That number is too low.`);
                    return false;
                }
            } else {
                if (value <= parseFloat(rules.max)) {
                    return true
                } else {
                    validation.get(field).errors.set('max', `That number is too high.`);
                    return false;
                }
            }
        } else {
            validation.get(field).errors.set('required', `This input is required for the calculation.`);
            return false;
        }
    }

    static validateAndClean(value, field, validation, rules) {
        validation.get(field).errors.clear();
        validation.get(field).isValid = true;
        if (rules.numeric && isNaN(CleaningService.cleanIfString(value))) {
            validation.get(field).errors.set('numeric', 'This input must be a number.');
            validation.get(field).isValid = false;
            return false;
        } else {
            let cleanValue = CleaningService.cleanIfString(value);
            const validValue = ValidationService.isValid(cleanValue, field, rules, validation);
            if (value === '') {
                cleanValue = value
            }
            validation.get(field).isValid = validation.get(field).errors.size === 0;
            if (validValue) {
                return cleanValue;
            }
            return false;
        }
    }
}
