<template hidden>
    <button type="button"
            @click="addCalculation"
            class="min-w-11/12 md:min-w-1/2 xl:min-w-1/3 flex items-center justify-center
            border-dashed border-gray-500 border-2 focus-ring focus-ring-muted
            py-6 px-8
            w-full
            fill-current text-gray-400">
        <font-awesome-icon icon="plus" size="7x" />
    </button>
</template>

<script>
    export default {
        name: "AddCalculation",
        methods: {
            addCalculation: function() {
                this.$emit('added-calculation');
            }
        }
    };
</script>

<style scoped lang="scss">

    .basis-w-1\/3 {
        flex: 1 0 auto;
        flex-basis: unquote("#{(4/12)*100}%");
    }
</style>
