<template hidden>
  <section class="card">
    <div class="flex flex-auto justify-center text-center mb-6">
      <h2 class="font-bold text-xl text-gray-900 flex flex-col">
        <span>{{ breakdown.name }}</span>
        <span class="text-sm lg:text-xs text-gray-600 font-medium pb-2">Started at <sup>&#36;</sup>{{
            commaSeparate(balance)
          }}</span>
      </h2>
    </div>
    <div class="flex flex-col justify-between mb-3">
      <div class="flex justify-between w-full mb-10 flex-wrap lg:flex-nowrap">
        <div
            class="order-1 lg:order-none border-gray-500 lg:border-solid lg:border-r w-full lg:w-1/2 lg:pr-2 lg:mr-2 text-center">
          <p class="text-sm lg:text-base text-gray-600 font-medium pb-2 text-left lg:text-center">Payment</p>
          <ol v-if="breakdown.payments && breakdown.payments.length > 1">
            <li v-for="(payment, index) in breakdown.payments" :key="index">
              <p class="text-gray-900 flex justify-between content-start">
                <small class="text-gray-600 flex-shrink text-left">{{ payment.name }}</small>
                <span class="mr-0 flex-shrink-0 text-right"><sup>&#36;</sup> {{ commaSeparate(payment.value) }}</span>
              </p>
            </li>
          </ol>
          <hr v-if="breakdown.payments && breakdown.payments.length > 1" class="border-gray-500 border-b my-2"/>
          <p class="text-gray-900 text-3xl font-bold"
             :class="{'text-right': breakdown.payments && breakdown.payments.length > 1}"><sup>&#36;</sup>
            {{ commaSeparate(totalPayment) }}</p>
        </div>
        <div class="w-full lg:w-1/2 text-center lg:mb-0 mb-2">
          <p class="text-sm lg:text-base text-gray-600 font-medium pb-2">{{ labelOfTime }}</p>
          <p class="text-gray-900 text-3xl font-bold">{{ lengthOfTime }}</p>
          <p class="text-sm lg:text-xs text-gray-600 font-medium pb-2">from the first</p>
        </div>
      </div>
      <div>
        <div class="flex justify-between">
          <template v-if="breakdown.type === loan" hidden>
            <div>
              <p class="text-gray-600 font-medium text-sm">Principal Paid</p>
              <p class="text-gray-900">&#36; {{ commaSeparate(totalPrincipal) }}</p>
            </div>
            <div class="text-right">
              <p class="text-gray-600 font-medium text-sm">Interest Paid</p>
              <p class="text-gray-900">&#36; {{ commaSeparate(totalInterest) }}</p>
            </div>
          </template>
          <template v-if="breakdown.type === savings" hidden>
            <div>
              <p class="text-gray-600 font-medium text-sm">Contribution</p>
              <p class="text-gray-900">&#36; {{ commaSeparate(totalPrincipal + totalInterest) }}</p>
            </div>
            <div class="text-right">
              <p class="text-gray-600 font-medium text-sm">Original Balance</p>
              <p class="text-gray-900">&#36; {{ commaSeparate(balance) }}</p>
            </div>
          </template>
        </div>
        <hr class="border-gray-500 border-b my-2"/>
        <p class="text-gray-900 text-3xl font-bold text-center">
          <span v-if="breakdown.type === loan"><sup>$</sup> {{ commaSeparate(totalPrincipal + totalInterest) }}</span>
          <span v-if="breakdown.type === savings"><sup>$</sup> {{
              commaSeparate(balance + totalPrincipal + totalInterest)
            }}</span>
          <span class="text-gray-600 font-medium text-sm block">{{ breakdown.type === loan ? 'Total' : 'Saved' }}</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import {CalculationType} from "./Calculation";
import moment from "moment";
import {$ as m$, in$} from 'moneysafe';
import {calculateTotalInterest, calculateTotalPrincipal} from "@/services/CalculationService";

export default {
  name: "CalculationBreakdown",
  props: {
    breakdown: {
      type: Object,
      default: () => ({})
    },
  },
  created: function () {
    this.determineLengthOfTime();
  },
  beforeUpdate: function () {
    this.determineLengthOfTime();
  },
  data: function () {
    return {
      savings: CalculationType.SAVINGS,
      loan: CalculationType.LOAN,
      lengthOfTime: 0,
      labelOfTime: 'Months',
    };
  },
  methods: {
    commaSeparate: function (value) {
      return new Intl.NumberFormat().format(value);
    },
    determineLengthOfTime: function () {
      const end = this.breakdown.endMoment;
      if (end !== undefined) {
        const months = end.diff(moment(), 'months');
        if (months >= 12) {
          this.labelOfTime = 'Years';
          this.lengthOfTime = this.breakdown.endMoment.diff(moment(), 'years');
        } else {
          this.labelOfTime = 'Months';
          this.lengthOfTime = months;
        }
      }
    }
  },
  computed: {
    totalPrincipal: function () {
      return calculateTotalPrincipal(this.breakdown.amortization);
    },
    totalInterest: function () {
      return calculateTotalInterest(this.breakdown.amortization);
    },
    totalPayment: function () {
      if (Array.isArray(this.breakdown.payments)) {
        return this.breakdown.payments.reduce((sum, payment) => (in$(m$(sum) + m$(payment.value))), m$(0));
      } else {
        return 0;
      }
    },
    balance: function () {
      return this.breakdown.balance;
    },
  }
}
</script>

<style scoped>

</style>
