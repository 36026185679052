export default class CleaningService {
    /**
     * Clean a numerical value
     *
     * This can be values like the following:
     * - {string} 500
     * - {number} 400
     * - {string} 500.00
     * - {string} $100
     *
     * @param value - the value to clean
     * @return {number|*} - return either a string that's been cleaned to a number or immediately return the number given for cleaning.
     */
    static clean(value) {
        if (value !== '') {
            value = value.replace(/[^0-9.]/g, '');
            if (value.match(/\./) !== null) {
                return parseFloat(value)
            }
            return Number(value)
        } else {
            return value;
        }
    }

    static cleanIfString(value) {
        if (typeof value === 'string') {
            return CleaningService.clean(value);
        }
        return value;
    }
}
