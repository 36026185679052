<template hidden>
  <section class="mt-10">
    <CalculationStatsTotal
        :total-interest="commaSeparate(totalInterest)"
        :total-principal="commaSeparate(totalPrincipal)"
        :interest-segment="calculateInterestSegment"
        :principal-segment="calculatePrincipalSegment"
        :furthest-end-moment="totalTimeForGoal"
    />
    <div class="mb-5 flex justify-end">
      <button class="card-cond flex gap-3 items-center text-gray-600" @click="handlePrintPopup()" type="button">
        <font-awesome-icon icon="print"/>
        Print Results
      </button>
    </div>
    <div class="grid-3-up mb-5">
      <CalculationBreakdown
          v-for="(breakdown, index) in results"
          :key="breakdown.name + index"
          :breakdown="breakdown"
      />
    </div>
  </section>
</template>

<script>
import CalculationStatsTotal from "./CalculationStatsTotal";
import CalculationBreakdown from "./CalculationBreakdown";
import {CalculationType} from './Calculation';
import moment from "moment";
import {$ as m$, in$} from "moneysafe";
import {calculateTotalInterest, calculateTotalPrincipal} from "@/services/CalculationService";

export default {
  name: "CalculationResult",
  components: {CalculationBreakdown, CalculationStatsTotal},
  props: {
    results: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    commaSeparate: function (value) {
      return new Intl.NumberFormat().format(value);
    },
    handlePrintPopup: function () {
      const DATE_FORMAT = 'MMM YYYY';
      const printWindow = window.open('', 'printresults', 'status=1,width=1120,height=600');
      const date = new moment();
      printWindow.document.title = `Goals ${date.format('MM-DD-YYYY')}`;
      const that = this;
      const tableHeaders = [{
        header: 'Goal',
        key: 'name',
        calc(value) {
          return value;
        }
      }, {
        header: 'Start Date',
        key: 'beginMoment',
        calc(value) {
          return value.format(DATE_FORMAT).toUpperCase();
        }
      }, {
        header: 'End Date',
        key: 'endMoment',
        calc(value) {
          return value.format(DATE_FORMAT).toUpperCase();
        }
      }, {
        header: 'Original Payment',
        key: 'payment',
        calc(value) {
          return `&#36; ${that.commaSeparate(value)}`
        }
      }, {
        header: 'Combined Payment',
        key: 'payments',
        calc: function (values) {
          if (Array.isArray(values)) {
            return `&#36; ${that.commaSeparate(values.reduce((sum, payment) => (in$(m$(sum) + m$(payment.value))), m$(0)))}`;
          } else {
            return 0;
          }
        }
      }, {
        header: 'Total Paid',
        calc(result) {
          const totalPrincipal = calculateTotalPrincipal(result.amortization);
          const totalInterest = calculateTotalInterest(result.amortization);
          if (result.type === CalculationType.SAVINGS) {
            return `&#36; ${that.commaSeparate(result.balance + totalPrincipal + totalInterest)}`
          } else if (result.type === CalculationType.LOAN) {
            return `&#36; ${that.commaSeparate(totalPrincipal + totalInterest)}`
          }
        }
      }, {
        header: 'Total Months',
        key: 'months',
        calc(value) {
          return value;
        }
      }];
      const table = printWindow.document.createElement('table');
      table.style.width = '100%';
      table.style.borderCollapse = 'collapse';
      table.style.fontSize = '10px';
      table.style.fontFamily = 'monospace';

      const tableHeader = printWindow.document.createElement('thead');

      const tableHeaderRow = printWindow.document.createElement('tr');

      tableHeaders.forEach(({header}) => {
        const th = printWindow.document.createElement('th');
        th.style.borderBottom = '2px solid black';
        th.style.padding = '5px';
        th.style.textAlign = 'left';
        th.innerText = header;
        tableHeaderRow.append(th);
      })

      tableHeader.append(tableHeaderRow);
      table.append(tableHeader);

      const tdBody = table.createTBody();
      this.results.forEach(result => {
        const tdRow = tdBody.insertRow();
        tableHeaders.forEach(({key, calc}) => {
            const cell = tdRow.insertCell();
            cell.style.padding = '3.5px 5px';
          if (key) {
            cell.innerHTML = calc(result[key]);
          } else {
            cell.innerHTML = calc(result);
          }
        })
        tdBody.append(tdRow);
      })
      table.append(tdBody);

      printWindow.document.body.append(table);

      // Pop up the print window.
      // @see https://stackoverflow.com/a/242190/4233452
      printWindow.print();
      // The above will block this time out, the once printed or canceled the pop up will neatly close.
      setTimeout(() => printWindow.close(), 100)
    }
  },
  computed: {
    totalPrincipal: function () {
      return this.results.reduce((sum, result) => {
        return (sum + result.amortization.reduce((sum, item) => (sum + item.principalPayment), 0));
      }, 0);
    },
    totalInterest: function () {
      return this.results.reduce((sum, result) => {
        return (sum + result.amortization.reduce((sum, item) => (sum + Math.round(item.interestPayment)), 0));
      }, 0);
    },
    calculatePrincipalSegment: function () {
      return Math.round((this.totalPrincipal / (this.totalPrincipal + this.totalInterest)) * 100)
    },
    calculateInterestSegment: function () {
      return Math.round((this.totalInterest / (this.totalPrincipal + this.totalInterest)) * 100)
    },
    totalTimeForGoal: function () {
      return this.results
          .map(result => result.endMoment)
          .reduce((previousLatestMoment, moment) => {
            return (previousLatestMoment.isAfter(moment)) ? previousLatestMoment : moment;
          }, moment());
    },
  }
}
</script>

<style scoped lang="scss">
.grid-3-up {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: unset;
  grid-row-gap: theme('spacing.5');

  @media (min-width: theme('screens.md')) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: theme('spacing.3');
  }

  @media (min-width: theme('screens.lg')) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: theme('spacing.3');
  }
}
</style>
