<template hidden>
    <section class="flex-auto card mb-8">
        <h2 class="font-bold text-xl mb-6 text-gray-900">Your Results</h2>
        <div class="flex justify-between mb-6">
            <div>
                <p class="lg:text-base text-sm text-gray-600 font-medium">Total Principal Paid</p>
                <p class="lg:text-base text-sm text-gray-900">&#36; {{ totalPrincipal }}</p>
            </div>
            <div class="text-center">
                <p class="lg:text-base text-sm text-gray-600 font-medium pb-2">Total Months</p>
                <p class="text-gray-900 text-3xl font-bold">{{ totalTimeInMonths }}</p>
            </div>
            <div class="text-right">
                <p class="lg:text-base text-sm text-gray-600 font-medium">Total Interest Paid</p>
                <p class="lg:text-base text-sm text-gray-900">&#36; {{ totalInterest }}</p>
            </div>
        </div>
        <div>
            <div class="flex w-full">
                <div v-if="interestSegment < 100" class="payment-segment bg-primary" :class="{ 'hide-segment': principalSegment <= 5 }" :style="{ width: principalSegment + '%'}"></div>
                <div v-if="principalSegment < 100" class="payment-segment bg-secondary ml-2" :class="{ 'hide-segment': interestSegment <= 5 }" :style="{ width: interestSegment + '%'}"></div>
            </div>
            <div class="flex w-full">
                <p class="flex flex-col text-gray-900 font-medium text-center" :style="{ minWidth: principalSegment + '%'}">
                    <span class="lg:text-lg text-base">{{ principalSegment }}% Principal</span>
                    <span class="lg:text-base text-sm">&#36; {{ totalPrincipal }}</span>
                </p>
                <p v-if="interestSegment >= 5 && principalSegment < 100" class="flex flex-col text-gray-900 font-medium text-center" :style="{ minWidth: interestSegment + '%'}">
                    <span class="lg:text-lg text-based">{{ interestSegment }}% Interest</span>
                    <span class="lg:text-base text-sm">&#36; {{ totalInterest }}</span>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from "moment";

    export default {
        name: "CalculationStatsTotal",
        props: {
            totalPrincipal: {
                type: String,
                default: '0'
            },
            totalInterest: {
                type: String,
                default: '0'
            },
            principalSegment: {
                type: Number,
                default: 0
            },
            interestSegment: {
                type: Number,
                default: 0
            },
            furthestEndMoment: {
                type: Object,
                default: () => moment()
            }
        },
        computed: {
            totalTimeInMonths: function () {
                return this.furthestEndMoment.diff(moment(), 'months');
            },
        }
    }
</script>

<style scoped lang="scss">
    .payment-segment {
        @apply h-3 relative mb-8;
        min-width: 1px;

        &::after {
            @apply border-l h-3;
            content: "";
            position: absolute;
            top: calc(100% + theme('spacing.3'));
            left: 50%;
            border-left-color: theme('colors.gray.600');
        }

        &.hide-segment {
            &::after {
                content: unset;
            }
        }
    }
</style>
