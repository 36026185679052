<template hidden>
  <div class="min-w-11/12 md:min-w-1/2 xl:min-w-1/3 mr-3 card">
    <div class="flex justify-between mb-2">
      <nav class="flex items-baseline w-full justify-between mr-4">
        <h2 v-if="currentTab === loan" class="w-full text-base lg:text-lg font-bold text-gray-900">
          {{ calculation.subType }} {{ index + 1 }}
        </h2>
        <h2 v-if="currentTab === savings" class="w-full text-base lg:text-lg font-bold text-gray-900">
          {{ calculation.type }} {{ index + 1 }}
        </h2>
        <ul class="flex mr-4">
          <li class="mx-2">
            <button type="button" class="px-3 py-1 text-gray-900 focus-ring focus-ring-muted rounded-full move-left"
                    :class="{'disabled': index === 0}"
                    @click="onMove(index, index - 1)">
              <font-awesome-icon icon="arrow-left"/>
            </button>
          </li>
          <li class="mx-2">
            <button type="button" class="px-3 py-1 text-gray-900 focus-ring focus-ring-muted rounded-full move-right"
                    :class="{'disabled': lastIndex === index}"
                    @click="onMove(index, index + 1)">
              <font-awesome-icon icon="arrow-right"/>
            </button>
          </li>
        </ul>
      </nav>
      <button type="button"
              class="justify-end ml-auto mb-4 fill-current text-gray-600 focus-ring focus-ring-muted remove-calculation"
              @click="onRemove">
        <font-awesome-icon icon="times" size="2x"/>
      </button>
    </div>
    <div class="flex justify-between mb-4">
      <button type="button"
              class="lg:text-base text-sm rounded-lg focus-ring w-full mr-4 sm:mr-0 sm:w-2/5 loan-btn"
              @click="onTypeChange(loan)"
              :class="{
                    'active-btn focus-ring-primary': currentTab === loan,
                    'inactive-btn focus-ring-muted': currentTab !== loan
                    }">
        Loan
      </button>
      <button type="button"
              class="lg:text-base text-sm rounded-lg focus-ring w-full sm:w-2/5 savings-btn"
              @click="onTypeChange(savings)"
              :class="{
                    'active-btn focus-ring-primary': currentTab === savings,
                    'inactive-btn focus-ring-muted': currentTab !== savings
                    }">
        Savings
      </button>
    </div>
    <section v-if="currentTab === loan">
      <div class="flex sm:flex-row flex-col mb-2">
        <ul>
          <li class="mb-4 xl:mb-0 mr-0 sm:mr-4 block sm:inline-block">
            <label class="inline-flex items-center">
              <input type="radio"
                     class="focus-ring focus-ring-primary"
                     @change="onSubTypeChange(subType.LOAN_CREDIT_CARD)"
                     v-bind:value="subType.LOAN_CREDIT_CARD"
                     v-model="calculation.subType"
                     checked
              />
              <span class="ml-1 text-gray-700 font-semibold">Credit Card</span>
            </label>
          </li>
          <li class="mb-4 xl:mb-0 mr-0 sm:mr-4 block sm:inline-block">
            <label class="inline-flex items-center">
              <input type="radio"
                     class="focus-ring focus-ring-primary"
                     @change="onSubTypeChange(subType.LOAN_AUTO)"
                     v-bind:value="subType.LOAN_AUTO"
                     v-model="calculation.subType"
              />
              <span class="ml-1 text-gray-700 font-semibold">Auto</span>
            </label>
          </li>
          <li class="mb-4 xl:mb-0 mr-0 sm:mr-4 block sm:inline-block">
            <label class="inline-flex items-center">
              <input type="radio"
                     class="focus-ring focus-ring-primary"
                     @change="onSubTypeChange(subType.LOAN_MORTGAGE)"
                     v-bind:value="subType.LOAN_MORTGAGE"
                     v-model="calculation.subType"/>
              <span class="ml-1 text-gray-700 font-semibold">Mortgage</span>
            </label>
          </li>
        </ul>
      </div>
      <div class="flex sm:flex-row flex-col mb-4">
        <fieldset class="w-full mb-4 sm:mr-4 sm:mb-0  relative">
          <label :for="`loan-balance-${index}`"
                 class="lg:text-base text-sm font-semibold text-gray-900 block mb-2">Loan Balance</label>
          <div class="relative position-icon">
            <input :id="`loan-balance-${index}`" class="control control-icon-before w-full loan-balance"
                   :class="{'is-invalid': !calculation.validation.get('loanBalance').isValid}"
                   type="text"
                   name="loanBalance"
                   required="required"
                   :value="calculation.balance"
                   @keypress="preventBadInput"
                   @input="onBalanceChange"/>
            <div class="absolute fill-current text-gray-600">&#36;</div>
          </div>
          <p v-if="calculation.validation.get('loanBalance').errors.has('required')">
            <small class="text-red-600">{{
                calculation.validation.get('loanBalance').errors.get('required')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('loanBalance').errors.has('min')">
            <small class="text-red-600">{{ calculation.validation.get('loanBalance').errors.get('min') }}
            </small>
          </p>
          <p v-if="calculation.validation.get('loanBalance').errors.has('max')">
            <small class="text-red-600">{{ calculation.validation.get('loanBalance').errors.get('max') }}
            </small>
          </p>
          <p v-if="calculation.validation.get('loanBalance').errors.has('numeric')">
            <small class="text-red-600">{{
                calculation.validation.get('loanBalance').errors.get('numeric')
              }}
            </small>
          </p>
        </fieldset>

        <fieldset class="w-full relative">
          <label :for="`interest-rate-${index}`"
                 class="lg:text-base text-sm font-semibold text-gray-900 block mb-2">Interest Rate</label>
          <div class="relative position-icon">
            <input :id="`interest-rate-${index}`" class="control control-icon-after w-full interest-rate"
                   :class="{'is-invalid': !calculation.validation.get('interestRate').isValid}"
                   type="text"
                   name="interestRate"
                   required="required"
                   :value="calculation.interestRate"
                   @keypress="preventBadInput"
                   @input="onInterestRateChange"/>
            <div class="absolute fill-current text-gray-600">&#37;</div>
          </div>
          <p v-if="calculation.validation.get('interestRate').errors.has('required')">
            <small class="text-red-600">{{
                calculation.validation.get('interestRate').errors.get('required')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('interestRate').errors.has('min')">
            <small class="text-red-600">{{ calculation.validation.get('interestRate').errors.get('min') }}
            </small>
          </p>
          <p v-if="calculation.validation.get('interestRate').errors.has('max')">
            <small class="text-red-600">{{ calculation.validation.get('interestRate').errors.get('max') }}
            </small>
          </p>
          <p v-if="calculation.validation.get('interestRate').errors.has('numeric')">
            <small class="text-red-600">{{
                calculation.validation.get('interestRate').errors.get('numeric')
              }}
            </small>
          </p>
        </fieldset>
      </div>

      <fieldset class="flex sm:flex-row flex-col">
        <div class="w-full sm:w-1/3 relative">
          <label :for="`loan-payment-${index}`"
                 class="lg:text-base text-sm font-semibold text-gray-900 block mb-2">Payment</label>
          <div class="relative position-icon">
            <input :id="`loan-payment-${index}`" class="control control-icon-before w-full loan-payment"
                   :class="{'is-invalid': !calculation.validation.get('loanPayment').isValid}"
                   type="text"
                   name="loanPayment"
                   required="required"
                   :value="calculation.payment"
                   @keypress="preventBadInput"
                   @input="onPaymentChange"/>
            <div class="absolute fill-current text-gray-600">&#36;</div>
          </div>
          <p v-if="calculation.validation.get('loanPayment').errors.has('required')">
            <small class="text-red-600">{{
                calculation.validation.get('loanPayment').errors.get('required')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('loanPayment').errors.has('min')">
            <small class="text-red-600">{{ calculation.validation.get('loanPayment').errors.get('min') }}
            </small>
          </p>
          <p v-if="calculation.validation.get('loanPayment').errors.has('max')">
            <small class="text-red-600">{{ calculation.validation.get('loanPayment').errors.get('max') }}
            </small>
          </p>
          <p v-if="calculation.validation.get('loanPayment').errors.has('numeric')">
            <small class="text-red-600">{{
                calculation.validation.get('loanPayment').errors.get('numeric')
              }}
            </small>
          </p>
        </div>
      </fieldset>
    </section>
    <section v-if="currentTab === savings">
      <div class="flex sm:flex-row flex-col mb-4">
        <fieldset class="w-full mb-4 sm:mr-4 sm:mb-0 relative">
          <label :for="`savings-balance-${index}`"
                 class="lg:text-base text-sm font-semibold text-gray-900 block mb-2">Savings Balance</label>
          <div class="relative position-icon">
            <input :id="`savings-balance-${index}`" class="control control-icon-before w-full savings-balance"
                   :class="{'is-invalid': !calculation.validation.get('savingsBalance').isValid}"
                   type="text"
                   name="savingsBalance"
                   required="required"
                   :value="calculation.balance"
                   @keypress="preventBadInput"
                   @input="onBalanceChange"/>
            <div class="absolute fill-current text-gray-600">&#36;</div>
          </div>
          <p v-if="calculation.validation.get('savingsBalance').errors.has('required')">
            <small class="text-red-600">{{
                calculation.validation.get('savingsBalance').errors.get('required')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('savingsBalance').errors.has('min')">
            <small class="text-red-600">{{
                calculation.validation.get('savingsBalance').errors.get('min')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('savingsBalance').errors.has('max')">
            <small class="text-red-600">{{
                calculation.validation.get('savingsBalance').errors.get('max')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('savingsBalance').errors.has('numeric')">
            <small class="text-red-600">{{
                calculation.validation.get('savingsBalance').errors.get('numeric')
              }}
            </small>
          </p>
        </fieldset>

        <fieldset class="w-full relative">
          <label :for="`savings-payment-${index}`"
                 class="lg:text-base text-sm font-semibold text-gray-900 block mb-2">Payment</label>
          <div class="relative position-icon">
            <input :id="`savings-payment-${index}`" class="control control-icon-before w-full savings-payment"
                   :class="{'is-invalid': !calculation.validation.get('savingsPayment').isValid}"
                   type="text"
                   name="savingsPayment"
                   required="required"
                   :value="calculation.payment"
                   @keypress="preventBadInput"
                   @input="onPaymentChange"/>
            <div class="absolute fill-current text-gray-600">&#36;</div>
          </div>
          <p v-if="calculation.validation.get('savingsPayment').errors.has('required')">
            <small class="text-red-600">{{
                calculation.validation.get('savingsPayment').errors.get('required')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('savingsPayment').errors.has('min')">
            <small class="text-red-600">{{
                calculation.validation.get('savingsPayment').errors.get('min')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('savingsPayment').errors.has('max')">
            <small class="text-red-600">{{
                calculation.validation.get('savingsPayment').errors.get('max')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('savingsPayment').errors.has('numeric')">
            <small class="text-red-600">{{
                calculation.validation.get('savingsPayment').errors.get('numeric')
              }}
            </small>
          </p>
        </fieldset>
      </div>

      <fieldset class="flex sm:flex-row flex-col">
        <div class="w-full sm:w-1/3 relative">
          <label :for="`savings-goal-${index}`"
                 class="lg:text-base text-sm font-semibold text-gray-900 block mb-2">Savings Goal</label>
          <div class="relative position-icon">
            <input :id="`savings-goal-${index}`" class="control control-icon-before w-full savings-goal"
                   :class="{'is-invalid': !calculation.validation.get('savingsGoal').isValid}"
                   type="text"
                   name="savingsGoal"
                   required="required"
                   :value="calculation.goal"
                   @keypress="preventBadInput"
                   @input="onGoalChange"/>
            <div class="absolute fill-current text-gray-600">&#36;</div>
          </div>
          <p v-if="calculation.validation.get('savingsGoal').errors.has('required')">
            <small class="text-red-600">{{
                calculation.validation.get('savingsGoal').errors.get('required')
              }}
            </small>
          </p>
          <p v-if="calculation.validation.get('savingsGoal').errors.has('min')">
            <small class="text-red-600">{{ calculation.validation.get('savingsGoal').errors.get('min') }}
            </small>
          </p>
          <p v-if="calculation.validation.get('savingsGoal').errors.has('max')">
            <small class="text-red-600">{{ calculation.validation.get('savingsGoal').errors.get('max') }}
            </small>
          </p>
          <p v-if="calculation.validation.get('savingsGoal').errors.has('numeric')">
            <small class="text-red-600">{{ calculation.validation.get('savingsGoal').errors.get('numeric') }}
            </small>
          </p>
        </div>
      </fieldset>
    </section>
  </div>
</template>

<script>
import {CalculationModel} from './Calculator';

export const CalculationType = {
  LOAN: 'Loan',
  SAVINGS: 'Savings',
};

export class CalculationSubType {
  LOAN_CREDIT_CARD = 'Credit Card';
  LOAN_AUTO = 'Auto';
  LOAN_MORTGAGE = 'Mortgage';
}

export default {
  name: "Calculation",
  props: {
    index: {
      type: Number,
    },
    lastIndex: {
      type: Number,
    },
    calculation: {
      type: Object,
      default: CalculationModel
    },
    minValue: {
      type: Number,
      default: 1
    },
    maxValue: {
      type: Number,
      default: 1000000
    },
  },
  data: () => {
    const data = {
      loan: CalculationType.LOAN,
      savings: CalculationType.SAVINGS,
      currentTab: CalculationType.LOAN,
      subType: new CalculationSubType(),
      maxInterestRate: 100,
    };
    data.selectedSubType = data.subType.LOAN_CREDIT_CARD;
    return data;
  },
  methods: {
    preventBadInput(e) {
      if (
          (e.which < 48 || e.which > 57) && // digits
          e.which !== 44 &&
          e.which !== 46
      ) {
        // comma, period
        e.preventDefault()
      }
    },
    onTypeChange: function (type) {
      this.currentTab = type;
      const isOverride = true;
      if (CalculationType.SAVINGS === type) {
        this.$emit('interest-rate-change', null, this.index, isOverride);
        this.$emit('sub-type-change', null, this.index);
      } else {
        this.$emit('sub-type-change', this.selectedSubType, this.index);
      }
      this.$emit('type-change', type, this.index);
    },
    onSubTypeChange: function (subType) {
      this.selectedSubType = subType;
      this.$emit('sub-type-change', this.selectedSubType, this.index);
    },
    onBalanceChange: function (event) {
      this.$emit('balance-change', event.target.value, this.currentTab, this.index);
    },
    onInterestRateChange: function (event) {
      this.$emit('interest-rate-change', event.target.value, this.index);
    },
    onPaymentChange: function (event) {
      this.$emit('payment-change', event.target.value, this.currentTab, this.index);
    },
    onGoalChange: function (event) {
      this.$emit('goal-change', event.target.value, this.currentTab, this.index);
    },
    onRemove: function () {
      this.$emit('remove-calculation', this.index)
    },
    onMove: function (fromIndex, toIndex) {
      this.$emit('change-position', fromIndex, toIndex)
    },
  }
};
</script>

<style scoped lang="scss">
.basis-w-1\/3 {
  flex: 1 0 auto;
  flex-basis: unquote("#{(4/12)*100}%");
}

.active-btn {
  @apply bg-primary text-gray-800;
}

.inactive-btn {
  @apply bg-gray-300 text-gray-500;
}

.control {
  @apply bg-gray-200 border border-transparent border-solid rounded-sm py-2 px-3;
  border-left-width: 3px;

  &:focus, &:active {
    @apply bg-white border border-gray-200;
    border-left-width: 3px;
    outline: none;
    border-color: theme('colors.primary');
    transition: all 200ms ease-out;
  }

  &.is-invalid {
    @apply bg-white;
    border-color: theme('colors.red.600');
    color: theme('colors.red.600');

    &:focus, &:active {
      border-color: theme('colors.red.600');
      color: theme('colors.red.600');
    }
  }

  &-icon-before {
    @apply pl-8;
  }

  &-icon-after {
    @apply pr-8;
  }
}

.position-icon {
  .control-icon-before + .absolute {
    bottom: 0.5rem;
    left: 1rem;
  }

  .control-icon-after + .absolute {
    bottom: 0.5rem;
    right: 0.75rem;
  }
}

.disabled {
  @apply pointer-events-none cursor-not-allowed text-gray-400;
}
</style>
