<template hidden>
  <section>
    <h1 class="h1">Financial Goals</h1>
    <p class="text-gray-900 mb-10">Choosing the right strategy for your financial goals is important. It is
      especially good to eliminate debt as quickly as possible. One way to do it is through Accelerated Debt pay
      off, which is what this calculator uses. This calculator enables you to mix in Savings and Debt pay-off
      strategies, because it's important to have a fund for emergencies so you can stay out of debt and stay
      self-reliant.</p>
    <ul class="card mb-3">
      <li class="mb-4 xl:mb-0 mr-0 sm:mr-4 block sm:inline-block">
        <button type="button" class="w-full md:w-auto sort-button btn-sm focus-ring focus-ring-muted"
                @click="sortByLowestInterestRate">
          <font-awesome-icon class="fill-current text-gray-600" icon="sort-amount-down-alt"/>
          lowest Interest Rate
        </button>
      </li>
      <li class="mb-4 xl:mb-0 mr-0 sm:mr-4 block sm:inline-block">
        <button type="button" class="w-full md:w-auto sort-button btn-sm focus-ring focus-ring-muted"
                @click="sortByHighestInterestRate">
          <font-awesome-icon class="fill-current text-gray-600" icon="sort-amount-up-alt"/>
          highest Interest Rate
        </button>
      </li>
      <li class="mb-4 xl:mb-0 mr-0 sm:mr-4 block sm:inline-block">
        <button type="button" class="w-full md:w-auto sort-button btn-sm focus-ring focus-ring-muted"
                @click="sortByLowestBalance">
          <font-awesome-icon class="fill-current text-gray-600" icon="sort-amount-down-alt"/>
          lowest Balance
        </button>
      </li>
      <li class="block sm:inline-block lg:w-1/2 xl:w-auto">
        <button type="button" class="w-full md:w-auto sort-button btn-sm focus-ring focus-ring-muted"
                @click="sortByHighestBalance">
          <font-awesome-icon class="fill-current text-gray-600" icon="sort-amount-up-alt"/>
          highest Balance
        </button>
      </li>
    </ul>
    <form @submit.prevent="calculate">
      <div class="flex flex-nowrap single-track pb-6">
        <!-- It's hard to tell when a calculation moves positions - animations? -->
        <Calculation
            v-for="(calculation, index) in calculations"
            v-bind:key="calculation.uuid"
            :index="index"
            :last-index="calculations.length - 1"
            :calculation="calculation"
            :min-value="minValue"
            :max-value="maxValue"
            @type-change="onCalculationTypeChange(...arguments)"
            @sub-type-change="onCalculationSubTypeChange(...arguments)"
            @balance-change="onBalanceChange(...arguments)"
            @interest-rate-change="onInterestRateChange(...arguments)"
            @payment-change="onPaymentChange(...arguments)"
            @goal-change="onGoalChange(...arguments)"
            @remove-calculation="onRemoveCalculation(...arguments)"
            @change-position="onPositionChange(...arguments)"
        ></Calculation>
        <AddCalculation @added-calculation="onAddCalculation"></AddCalculation>
      </div>
      <p v-if="hasError && message !== null" class="mb-6">
        <small class="text-red-600">{{ message }}</small>
      </p>
      <button type="submit"
              class="w-full sm:w-2/5 bg-secondary text-gray-900 uppercase tracking-wider py-3 px-6 font-bold text-xl focus-ring focus-ring-secondary"
              @click="calculate"
              :disabled="hasError">Calculate
      </button>
      <CalculationResult v-if="results.length > 0" :results="results"></CalculationResult>
    </form>
  </section>
</template>

<script>
import Calculation, {CalculationSubType, CalculationType} from "./Calculation";
import AddCalculation from "./AddCalculation";
import CalculationResult from "./CalculationResult";
import uuidv1 from 'uuid';
import arrayMove from "array-move";
import CleaningService from '../services/CleaningService';
import ValidationService from '../services/ValidationService';
import {calculate, calculateInterestPayment} from "../services/CalculationService";

export function CalculationModel() {
  const subType = new CalculationSubType();
  this.uuid = uuidv1();
  this.type = CalculationType.LOAN;
  this.subType = subType.LOAN_CREDIT_CARD;
  this.balance = null;
  this.interestRate = null;
  this.payment = null;
  this.goal = null;
  this.validation = new ValidationModel();
}

export function ValidationModel() {
  return new Map([
    ['loanBalance', {
      isValid: true,
      errors: new Map(),
    }],
    ['interestRate', {
      isValid: true,
      errors: new Map(),
    }],
    ['loanPayment', {
      isValid: true,
      errors: new Map(),
    }],
    ['savingsBalance', {
      isValid: true,
      errors: new Map(),
    }],
    ['savingsPayment', {
      isValid: true,
      errors: new Map(),
    }],
    ['savingsGoal', {
      isValid: true,
      errors: new Map(),
    }]
  ]);
}

export default {
  name: "Calculator",
  components: {CalculationResult, AddCalculation, Calculation},
  data: () => ({
    calculations: [new CalculationModel()],
    hasError: false,
    message: null,
    results: [],
  }),
  props: {
    minValue: {
      type: Number,
      default: 1
    },
    maxValue: {
      type: Number,
      default: 1000000
    }
  },
  created: function () {
    this.onChangeRecalculateValidity();
  },
  methods: {
    onAddCalculation: function () {
      this.calculations.push(new CalculationModel());
      this.onChangeRecalculateValidity();
    },
    onRemoveCalculation: function (index) {
      this.calculations.splice(index, 1);
      this.onChangeRecalculateValidity();
    },
    onCalculationTypeChange: function (type, index) {
      this.$set(this.calculations[index], 'type', type);
      this.onChangeRecalculateValidity();
    },
    onCalculationSubTypeChange: function (subType, index) {
      this.$set(this.calculations[index], 'subType', subType);
      this.onChangeRecalculateValidity();
    },
    onBalanceChange: function (value, type, index) {
      let field = 'loanBalance';
      if (type === CalculationType.SAVINGS) {
        field = 'savingsBalance';
      }
      const validation = this.calculations[index].validation;
      const rules = {
        required: true,
        min: this.minValue,
        max: this.maxValue,
        numeric: true,
      };
      if (type === CalculationType.SAVINGS && index !== 0) {
        rules.min = 0;
      }
      const cleanValue = ValidationService.validateAndClean(value, field, validation, rules);

      if (cleanValue !== false) {
        this.$set(this.calculations[index], 'validation', validation);
        this.$set(this.calculations[index], 'balance', new Intl.NumberFormat().format(cleanValue));
        if (this.calculations[index].payment !== null) {
          // validate payment, after successful balance change.
          this.onPaymentChange(this.calculations[index].payment, type, index);
        }
      } else {
        this.$set(this.calculations[index], 'validation', validation);
        this.$set(this.calculations[index], 'balance', value);
      }
      this.onChangeRecalculateValidity();
    },
    onInterestRateChange: function (value, index, isValidReset = false) {
      if (!isValidReset) {
        const field = 'interestRate';
        const validation = this.calculations[index].validation;
        const rules = {
          required: true,
          min: 0,
          max: 99,
          numeric: true,
        };
        const cleanValue = ValidationService.validateAndClean(value, field, validation, rules);

        if (cleanValue !== false) {
          this.$set(this.calculations[index], 'validation', validation);
          this.$set(this.calculations[index], 'interestRate', cleanValue);
        } else {
          this.$set(this.calculations[index], 'validation', validation);
          this.$set(this.calculations[index], 'interestRate', value);
        }
      }
      this.onChangeRecalculateValidity();
    },
    onPaymentChange: function (value, type, index) {
      let field = 'loanPayment';
      if (type === CalculationType.SAVINGS) {
        field = 'savingsPayment';
      }
      const validation = this.calculations[index].validation;
      let max = this.maxValue;
      if (type === CalculationType.SAVINGS) {
        // goal and payment cannot be equal.
        if (this.calculations[index].goal !== null) {
          max = CleaningService.cleanIfString(this.calculations[index].goal) - 1;
        }
      } else {
        // balance and payment cannot be equal.
        if (this.calculations[index].balance !== null) {
          max = CleaningService.cleanIfString(this.calculations[index].balance) - 1;
        }
      }

      const rules = {
        required: true,
        min: 1,
        max,
        numeric: true,
      };
      if (index !== 0) {
        rules.min = 0;
      }
      const cleanValue = ValidationService.validateAndClean(value, field, validation, rules);

      if (cleanValue !== false) {
        this.$set(this.calculations[index], 'validation', validation);
        this.$set(this.calculations[index], 'payment', new Intl.NumberFormat().format(cleanValue));
      } else {
        this.$set(this.calculations[index], 'validation', validation);
        this.$set(this.calculations[index], 'payment', value);
      }
      this.onChangeRecalculateValidity();
    },
    onGoalChange: function (value, type, index) {
      const field = 'savingsGoal';
      const validation = this.calculations[index].validation;
      let min = this.minValue;
      if (type === CalculationType.SAVINGS) {
        // goal and balance cannot be equal.
        if (this.calculations[index].balance !== null) {
          min = CleaningService.cleanIfString(this.calculations[index].balance) + 1;
        }
      }
      const rules = {
        required: true,
        min: min,
        max: this.maxValue,
        numeric: true,
      };
      const cleanValue = ValidationService.validateAndClean(value, field, validation, rules);

      if (cleanValue !== false) {
        this.$set(this.calculations[index], 'validation', validation);
        this.$set(this.calculations[index], 'goal', new Intl.NumberFormat().format(cleanValue));
        // validate payment, after successful goal change.
        this.onPaymentChange(this.calculations[index].payment, type, index);
      } else {
        this.$set(this.calculations[index], 'validation', validation);
        this.$set(this.calculations[index], 'goal', value);
      }
      this.onChangeRecalculateValidity();
    },
    onPositionChange: function (fromIndex, toIndex) {
      this.calculations = arrayMove(this.calculations, fromIndex, toIndex);
    },
    onChangeRecalculateValidity: function () {
      const validity = this.calculations.filter(calculation => {
        const invalidValues = [];
        const mapIterator = calculation.validation.values();
        let nextCalcValidation = mapIterator.next();
        do {
          if (!nextCalcValidation.value.isValid) {
            invalidValues.push(nextCalcValidation.value.isValid);
          }
          nextCalcValidation = mapIterator.next();
        } while (!nextCalcValidation.done);
        // check if all fields are filled out in either the savings or loan
        if (calculation.type === CalculationType.LOAN) {
          if (calculation.balance === null
              || calculation.interestRate === null
              || calculation.payment === null
          ) {
            invalidValues.push(false);
          }
        } else if (calculation.type === CalculationType.SAVINGS) {
          if (calculation.goal === null
              || calculation.balance === null
              || calculation.payment === null
          ) {
            invalidValues.push(false);
          }
        }
        return invalidValues.length > 0
      });
      this.hasError = validity.length > 0;
    },
    calculate: function () {
      // validate that the payment is enough to cover the interest.
      let isValidationFailed = false;
      for (let i = 0; i < this.calculations.length; ++i) {
        let balance = null, payment = null;
        if (this.calculations[i].balance != null) {
          balance = CleaningService.cleanIfString(this.calculations[i].balance);
        }
        if (this.calculations[i].payment != null) {
          payment = CleaningService.cleanIfString(this.calculations[i].payment);
        }
        const interestRate = this.calculations[i].interestRate;
        if (balance != null && interestRate != null) {
          const calculatedInterest = calculateInterestPayment(balance, interestRate);
          if (calculatedInterest >= payment) {
            this.hasError = true;
            this.message = `Your payment of $${payment} is not enough to cover the interest. Try increasing your payment to more than $${new Intl.NumberFormat().format(calculatedInterest)}.`;
            isValidationFailed = true;
          } else {
            this.hasError = false;
            this.message = null;
          }
        }
      }

      if (!isValidationFailed) {
        this.results = calculate(this.calculations);
      }
    },
    sortByLowestInterestRate() {
      this.calculations.sort((a, b) => {
        if (a.interestRate != null && b.interestRate != null) {
          const aInterestRate = CleaningService.cleanIfString(a.interestRate);
          const bInterestRate = CleaningService.cleanIfString(b.interestRate);
          if (aInterestRate < bInterestRate) {
            return -1;
          }
          if (aInterestRate > bInterestRate) {
            return 1;
          }
          return 0;
        } else if (a.interestRate != null) {
          return 1;
        } else if (b.interestRate != null) {
          return -1;
        } else {
          return 0;
        }
      })
    },
    sortByHighestInterestRate() {
      this.calculations.sort((a, b) => {
        if (a.interestRate != null && b.interestRate != null) {
          const aInterestRate = CleaningService.cleanIfString(a.interestRate);
          const bInterestRate = CleaningService.cleanIfString(b.interestRate);
          if (aInterestRate > bInterestRate) {
            return -1;
          }
          if (aInterestRate < bInterestRate) {
            return 1;
          }
          return 0;
        } else if (a.interestRate != null) {
          return 1;
        } else if (b.interestRate != null) {
          return -1;
        } else {
          return 0;
        }
      })
    },
    sortByLowestBalance() {
      this.calculations.sort((a, b) => {
        let balanceA = CleaningService.cleanIfString(a.balance);
        let balanceB = CleaningService.cleanIfString(b.balance);

        if (a.type === CalculationType.SAVINGS) {
          balanceA = CleaningService.cleanIfString(a.goal) - CleaningService.cleanIfString(a.balance);
        }

        if (b.type === CalculationType.SAVINGS) {
          balanceB = CleaningService.cleanIfString(b.goal) - CleaningService.cleanIfString(b.balance);
        }

        if (balanceA < balanceB) {
          return -1;
        }

        if (balanceA > balanceB) {
          return 1;
        }

        return 0;
      })
    },
    sortByHighestBalance() {
      this.calculations.sort((a, b) => {
        let balanceA = CleaningService.cleanIfString(a.balance);
        let balanceB = CleaningService.cleanIfString(b.balance);

        if (a.type === CalculationType.SAVINGS) {
          balanceA = CleaningService.cleanIfString(a.goal) - CleaningService.cleanIfString(a.balance);
        }

        if (b.type === CalculationType.SAVINGS) {
          balanceB = CleaningService.cleanIfString(b.goal) - CleaningService.cleanIfString(b.balance);
        }

        if (balanceA > balanceB) {
          return -1;
        }

        if (balanceA < balanceB) {
          return 1;
        }

        return 0;
      })
    },
  }
};
</script>

<style scoped lang="scss">
.single-track {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

button[disabled] {
  @apply bg-gray-400 text-gray-500 cursor-not-allowed pointer-events-none;
}
</style>
