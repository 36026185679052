<template hidden>
  <header class="mb-4">
    <nav class="container mx-auto py-2 px-3">
      <p class="text-primary-dark font-bold">Goals</p>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Navigation"
};
</script>
